//查询产品
$.post(post_url+'product/usage',{
    e_id:JSON.parse(getCookie('user')).e_id,
    t_id:JSON.parse(getCookie('user')).t_id,
    token:JSON.parse(getCookie('user')).token,
},function(data){
    $('.info>.right .app>.loading').remove();
    error_fn(data.error)
    switch(data.error){
        case 0:
            //改为自己要用的数据
            var productobj=new Object();
            for(var i=0;i<data.products.length;i++){
                productobj[data.products[i].p_id]=[]
                for(var j=0;j<data.products[i].codes.length;j++){
                    productobj[data.products[i].p_id].push({'date':data.products[i].codes[j].expire,'status':data.products[i].codes[j].status});
                };
            };
            //没有数据
            if(JSON.stringify(productobj) =='{}'){
                $('.info .app').append(`
                    <div class="no">
                        <img src="/images/nothing.png" alt="Nothing">
                        <p>${i18n[lang].no_app_found}</p>
                    </div>
                `);
            }else{
                //循环数据
                for(var id in productobj){
                    if(productJSON[id]==undefined){
                        continue;
                    }
                    if(productJSON[id][lang]==undefined){
                        var product=productJSON[id]['en']
                    }else{
                        var product=productJSON[id][lang]
                    }
                    if(product){
                        var order_product='';
                            //判断产品是否免费
                            if(product['isFree']==1){
                                order_product+=`
                                <table>
                                    <tr>
                                        <td>${i18n[lang].plan} 1</td>
                                        <td>${i18n[lang].free_no_limit}</td>
                                        <td></td>
                                    </tr>
                                </table>
                                <div class="more_box">
                                <a href="${product['link']}">${i18n[lang].product_page}</a>
                                </div>
                                `;
                                $('.info .app').append(`
                                    <div class="flex_box">
                                        <div class="left">
                                            <img src="/images/icon/${product['icon'].split('.')[0]+'-66.'+product['icon'].split('.')[1]}" loading="lazy" alt="${product['name']}">
                                            <span>${product['name']}</span>
                                        </div>
                                        <div class="right">
                                            ${order_product}
                                        </div>
                                    </div>
                                `);
                             }else if(product['isFree']==0){
                                if(productobj[id].length){
                                    // 注册码的状态
                                    var productobjStatus=[];
                                    for(var i=0;i<productobj[id].length;i++){
                                        productobj[id][i]['status'].sort((a, b) => a - b);
                                        if(productobj[id][i]['status'][0]==4103){
                                            productobjStatus.push({'status':1});
                                        }else{
                                            productobjStatus.push({'status':productobj[id][i]['status'][0],'date':productobj[id][i]['date']});
                                        }
                                    };
                                    //正常
                                    var productobjVal_1=1;
                                    //过期
                                    var productobjVal_2=1;
                                    //禁用，退款
                                    var productobjVal_3=1;
                                    productobjStatus.sort((a,b)=>a.status-b.status);
                                    for(var i=0;i<productobjStatus.length;i++){
                                        if(productobjStatus[i]['status']==0){
                                            productobjVal_1=0;
                                            order_product+=`
                                            <table>
                                                <tr>
                                                    <td>${i18n[lang].plan} ${i+1}</td>
                                                    <td>${surplusTime(productobjStatus[i]['date'])}</td>
                                                    <td></td>
                                                </tr>
                                            </table>
                                            `
                                        }else if(productobjStatus[i]['status']==1&&productobjVal_1&&productobjVal_2){
                                            productobjVal_2=0;
                                            order_product+=`
                                            <table>
                                                <tr>
                                                    <td>${i18n[lang].plan} ${i+1}</td>
                                                    <td style="color: #D80303;">${i18n[lang].expired}</td>
                                                    <td></td>
                                                </tr>
                                            </table>
                                            `
                                        }else if(productobjStatus[i]['status']==4101&&productobjVal_1&&productobjVal_3){
                                            productobjVal_3=0;
                                            order_product+=`
                                            <table>
                                                <tr>
                                                    <td>${i18n[lang].plan} ${i+1}</td>
                                                    <td status="4101">${i18n[lang].unregistered}</td>
                                                    <td></td>
                                                </tr>
                                            </table>
                                            `
                                        }else if(productobjStatus[i]['status']==3001&&productobjVal_1&&productobjVal_3){
                                            productobjVal_3=0;
                                            order_product+=`
                                            <table>
                                                <tr>
                                                    <td>${i18n[lang].plan} ${i+1}</td>
                                                    <td status="3001">${i18n[lang].unregistered}</td>
                                                    <td></td>
                                                </tr>
                                            </table>
                                            `
                                        }
                                    };
                                    //判断是正常还是过期
                                    if(!productobjVal_1){
                                        order_product+=`
                                        <div class="more_box">
                                        <a class="btn" href="${product['buy-link']}">${i18n[lang].buy_more}</a>
                                        <a href="${product['link']}">${i18n[lang].product_page}</a>
                                        </div>`;
                                        $('.info .app').append(`
                                            <div class="flex_box">
                                                <div class="left">
                                                    <img src="/images/icon/${product['icon'].split('.')[0]+'-66.'+product['icon'].split('.')[1]}" loading="lazy" alt="${product['name']}">
                                                    <span>${product['name']}</span>
                                                    <img src="/images/member.svg" loading="lazy" alt="Member">
                                                    
                                                </div>
                                                <div class="right">
                                                    ${order_product}
                                                </div>
                                            </div>
                                        `);
                                    }else{
                                        order_product+=`
                                        <div class="again_box">
                                        <a class="btn" href="${product['buy-link']}">${i18n[lang].buy_again}</a>
                                        <a href="${product['link']}">${i18n[lang].product_page}</a>
                                        </div>`;
                                        $('.info .app').append(`
                                            <div class="flex_box">
                                                <div class="left">
                                                    <img src="/images/icon/${product['icon'].split('.')[0]+'-66.'+product['icon'].split('.')[1]}" loading="lazy" alt="${product['name']}">
                                                    <span>${product['name']}</span>
                                                    <img src="/images/non-member.svg" loading="lazy" alt="No Member">
                                                    
                                                </div>
                                                <div class="right">
                                                    ${order_product}
                                                </div>
                                            </div>
                                        `);
                                    }
                                }else{
                                    if(product['buy-link']){
                                        order_product+=`
                                        <table>
                                            <tr>
                                                <td>${i18n[lang].plan} 1</td>
                                                <td status="nocode">${i18n[lang].unregistered}</td>
                                                <td></td>
                                            </tr>
                                        </table>
                                        <div class="again_box">
                                        <a class="btn" href="${product['buy-link']}">${i18n[lang].buy_now}</a>
                                        <a href="${product['link']}">${i18n[lang].product_page}</a>
                                        </div>
                                        `;
                                    }else{
                                        order_product+=`
                                        <table>
                                            <tr>
                                                <td>${i18n[lang].plan} 1</td>
                                                <td status="nocode">${i18n[lang].unregistered}</td>
                                                <td></td>
                                            </tr>
                                        </table>
                                        `;
                                    }
                                    $('.info .app').append(`
                                        <div class="flex_box">
                                            <div class="left">
                                                <img src="/images/icon/${product['icon'].split('.')[0]+'-66.'+product['icon'].split('.')[1]}" loading="lazy" alt="${product['name']}">
                                                <span>${product['name']}</span>
                                                <img src="/images/non-member.svg" loading="lazy" alt="No Member">
                                                
                                            </div>
                                            <div class="right">
                                                ${order_product}
                                            </div>
                                        </div>
                                    `);
                                }
                            }else if(product['isFree']==2){
                                if(id==167 || id==173){
                                    (function(id){
                                        var benefits_ind;
                                        switch(id){
                                            case '167':
                                                benefits_ind=3;
                                                break;
                                            case '173':
                                                benefits_ind=4;
                                                break;
                                        }
                                        var order_product='';
                                        // 查询权益
                                        $.post(
                                            post_url+"benefit/status",
                                            {
                                                e_id:JSON.parse(getCookie('user')).e_id,
                                                t_id:JSON.parse(getCookie('user')).t_id,
                                                token:JSON.parse(getCookie('user')).token,
                                                product_id:id
                                            },function(data){
                                                error_fn(data.error)
                                                if(productJSON[id][lang]==undefined){
                                                    var product=productJSON[id]['en']
                                                }else{
                                                    var product=productJSON[id][lang]
                                                }
                                                switch(data.error){
                                                    case 0:
                                                        if(id==167){
                                                            order_product+="<p style='font-size:16px;color:#333;margin-bottom:16px;'>"+i18n[lang].hd_downloading+"</p><div class='scroll_box'>"
                                                        }else if(id==173){
                                                            order_product+="<p style='font-size:16px;color:#333;margin-bottom:16px;'>"+i18n[lang].image_upscaler_p+"</p><div class='scroll_box'>"
                                                        }else{}
                                                        $('.info>.right .app>.loading').remove();
                                                        var total=0;
                                                        var use=0;
                                                        var islifetime=0;
                                                        for(var i=0;i<data.subscriptions.length;i++){
                                                            if(data.subscriptions[i].expire!=0){
                                                                if(data.subscriptions[i].benefits[benefits_ind].quota>data.subscriptions[i].benefits[benefits_ind].usage){
                                                                    if(data.subscriptions[i].expire*1>Date.parse(new Date())/1000){
                                                                        order_product+=`
                                                                        <table class="isfree2">
                                                                            <tr>
                                                                                <td>${i18n[lang].Remaining} <span>${data.subscriptions[i].benefits[benefits_ind].quota-data.subscriptions[i].benefits[benefits_ind].usage} ${i18n[lang].images}</span></td>
                                                                                <td>${data.subscriptions[i].benefits[benefits_ind].quota} ${i18n[lang].images}/${i18n[lang].Month} (${getdate(data.subscriptions[i].since)} - ${getdate(data.subscriptions[i].expire)})</td>
                                                                            </tr>
                                                                        </table>
                                                                        `;
                                                                    }
                                                                }
                                                            }else{
                                                                islifetime=1;
                                                                total+=data.subscriptions[i].benefits[benefits_ind].quota*1
                                                                use+=data.subscriptions[i].benefits[benefits_ind].usage*1
                                                            }
                                                        }
                                                        if(islifetime){
                                                            order_product+=`
                                                            <table class="isfree2">
                                                                <tr>
                                                                    <td>${i18n[lang].Remaining} <span>${total-use} ${i18n[lang].images}</span></td>
                                                                    <td>${total} ${i18n[lang].images}/${i18n[lang].lifetime}</td>
                                                                </tr>
                                                            </table>
                                                            `;
                                                        }
                                                        order_product+=`</div>
                                                        <div class="more_box">
                                                            <a class="btn" href="${product['buy-link']}" target="_blank">${i18n[lang].buy_more}</a>
                                                            <a style="color:#3774FF;" href="${product['link']}" target="_blank">${i18n[lang].use_this_function}</a>
                                                        </div>
                                                        `;
                                                        $('.info .app').append(`
                                                            <div class="flex_box">
                                                                <div class="left">
                                                                    <img src="/images/icon/${product['icon'].split('.')[0]+'-66.'+product['icon'].split('.')[1]}" loading="lazy" alt="${product['name']}">
                                                                    <span>${product['name']}</span>
                                                                </div>
                                                                <div class="right">
                                                                    ${order_product}
                                                                </div>
                                                            </div>
                                                        `);
                                                        break;
                                                    case 26801:
                                                        error(i18n[lang].error_26801);
                                                        break;
                                                    case 26802:
                                                        error(i18n[lang].error_26802);
                                                        break;
                                                    case 26803:
                                                        error(i18n[lang].error_26803);
                                                        break;
                                                    case 26804:
                                                        error(i18n[lang].error_26804);
                                                        break;
                                                }
                                            }
                                        ).error(function(xhr){
                                            overtime(26899);
                                        });
                                    }(id))
                                }
                            }else{}
                        $('.warning .all_software').append(`
                            <div>
                                <img width="64" height="64" src="/images/icon/${product['icon']}" loading="lazy" alt="${product['name']}">
                                <p>${product['name']}</p>
                            </div>
                        `);
                    }
                };
            };
            break;
        case 24801:
            error(i18n[lang].error_24801);
            break;
    }
}).error(function(xhr){
    overtime(24899);
});
//根据时间戳计算得出有效期还有多久
function surplusTime(expireDate){
    if(expireDate=="0000-00-00 00:00:00"){
        return i18n[lang].lifetime;
    };
    var expireDate=new Date(expireDate);
    var currentDate=Date.parse(new Date());
    if(parseInt((expireDate-currentDate)/(1000*60*60*24))<=15){
        return '<span style="color: #D80303;">'+i18n[lang].remain+' '+parseInt((expireDate-currentDate)/(1000*60*60*24))+' '+i18n[lang].day_s+'</span>';
    }else{
        return i18n[lang].remain+" "+parseInt((expireDate-currentDate)/(1000*60*60*24))+" "+i18n[lang].day_s;
    }
}
// 时间戳转换
function getdate(time) {
    var now=new Date(time*1000)
    var y=now.getFullYear()
    var m=now.getMonth()+1
    var d=now.getDate()
    return (d < 10 ? "0" + d : d) + "/" + (m < 10 ? "0" + m : m) + "/" + y
}