(function($){$.fn.extend({device_confirm:function(){var userAgentInfo = navigator.userAgent;var Agents = ["Android", "iPhone","SymbianOS", "Windows Phone","iPad", "iPod"];var flag = true;for (var v = 0; v < Agents.length; v++) {if (userAgentInfo.indexOf(Agents[v]) > 0) {flag = false;break;}}return flag;}})})(jQuery);(function($){$.fn.extend({system_confirm:function(){var userAgentInfo = navigator.userAgent;if(userAgentInfo.indexOf("Win")>-1){return "win"}else if(userAgentInfo.indexOf("Mac")>-1){return "mac"}}})})(jQuery);if(window.innerWidth>956){$(".tab_index").mouseenter(function(){$("#submenu_container").addClass("active"); $(".submenu").eq($(this).index(".tab_index")).addClass("active").siblings(".submenu").removeClass("active");});$("#submenu_container").mouseleave(function(){$("#submenu_container").removeClass("active");$(".submenu").removeClass("active");});};function system_confirm(){var userAgentInfo = navigator.userAgent;if(userAgentInfo.indexOf("Win")>-1){return "win"}else if(userAgentInfo.indexOf("Mac")>-1){return "mac"}};function device_confirm(){var userAgentInfo = navigator.userAgent;var Agents = ["Android", "iPhone","SymbianOS", "Windows Phone","iPad", "iPod"];var flag = true;for (var v = 0; v < Agents.length; v++) {if (userAgentInfo.indexOf(Agents[v]) > 0) {flag = false;break;}}return flag;};
(function () {
    var isMac,device_com;
    if(system_confirm()=="mac"){isMac=true}
    else if(system_confirm()=="win"){isMac=false}
    if(device_confirm()==true){device_com=true}
    else if(device_confirm()==false){device_com=false}
    if (isMac && device_com == true) {
        $('.mac_btn_box').css({"display":"flex"});
        $('.mac_btn').css({"display":"block"});
        $('.win_btn_box').css({"display":"none"});
        $('.win_btn').css({"display":"none"});
        $('.mobile_btn').css({"display":"none"});
    } else if (!isMac && device_com == true) {
        $('.win_btn_box').css({"display":"flex"});
        $('.win_btn').css({"display":"block"});
        $('.mac_btn_box').css({"display":"none"});
        $('.mac_btn').css({"display":"none"});
        $('.mobile_btn').css({"display":"none"});
      
    } else if (device_com == false) {
        $('.pc_btn').css({"display":"none"});
        $('.mobile_btn').show();
    };
})();
//搜索框
function searchFn(){
    if($(window).width()>1180){
        if($('#q').attr('class').indexOf('active')<0){
            $('#q').addClass('active');
            return false;
        }else{
            if($('#q').val()==''){
                $('#q').removeClass('active');
                return false;
            }
        }
    }else{

    }
}
$('#searchcontainer').click(function(e){e.stopPropagation();})
$(document).click(function(){$('#q').removeClass('active');});

var header_user=$('.header .login_btn');
function resizeHeader(){
    $('.header .login_btn').remove()
    if($(window).width()>1180){
        $('.header_bg').hide();
        $('.header div>div.header_box>div>ul li').unbind('click');
        $('.header div>div.header_box>div>ul').addClass('header_nav');
        $('.header div>div.header_box>div>ul li').removeClass('active');
        $('.header .toggle').removeClass('active');
        $('.header div>div').removeClass('active');

        // 会员
        $('.header div>div.header_box>div').append(header_user);
        $('.header .login_btn .login_box').unbind('hover');
        $('.header .login_btn .login_box').hover(function(){
            $('.header .login_btn .login_box ul').show();
        },function(){
            $('.header .login_btn .login_box ul').hide();
        });
    }else{
        $('.header div>div.header_box>div>ul').removeClass('header_nav');
        $('.header div>div.header_box>div>ul li').unbind('click');
        $('.header div>div.header_box>div>ul li').click(function(){
            $(this).toggleClass('active').siblings().removeClass('active');
        });
        // 会员
        $('.header .toggle').before(header_user);
        $('.header .login_box').unbind('click');
        $('.header .login_box').click(function(){
            if($('.header_box').attr('class').indexOf('active')>-1){
                $('.header_box').removeClass('active');
                $('.header .toggle').removeClass('active');
            }
            if($('.header .login_box ul').css("display")=='none'){
                $('.header .login_box ul').show();
                $(".header_bg").show()
            }else{
                $('.header .login_box ul').hide();
                $(".header_bg").hide()
            }
        });
    }
};
resizeHeader();
$(window).resize(function(){
    resizeHeader();
});
$('.header .toggle').click(function(){
    $(this).toggleClass('active');
    $('.header div>div').toggleClass('active');
    $('.header .login_box ul').hide();
    if($(this).attr('class').indexOf('active')>-1){
        $(".header_bg").show()
    }else{
        $(".header_bg").hide()
    }
});
$(".header_bg").click(function(){
    $(this).hide();
    $('.header div>div').removeClass('active');
    $('.header .toggle').removeClass('active');
    $('.header .login_box ul').hide();
});