jQuery.fn.extend({
    rotation_chart: function (content, left, right, name, times) {
        times = times || 4000;
        var i = 0;
        var that = $(this);
        var time;
        content.height(content.children().eq(0).outerHeight(true));
        function run () {
            clearInterval(time);
            time = setInterval(function () {
                i++;
                if (i >= that.length) {
                    i = 0;
                }
                that.eq(i).addClass(name).siblings().removeClass(name);
                content.children().eq(i).fadeIn("slow").siblings().fadeOut("slow");
                content.height(content.children().eq(i).outerHeight(true));
            }, times)
        }
        run();
        try {
            left.click(function () {
                i--;
                if (i < 0) {
                    i = that.length - 1;
                }
                that.eq(i).addClass(name).siblings().removeClass(name);
                content.children().eq(i).fadeIn("slow").siblings().fadeOut("slow");
                content.height(content.children().eq(i).outerHeight(true));
                clearInterval(time);
                run();
            });
        }
        catch (e) {

        };
        try {
            right.click(function () {
                i++;
                if (i >= that.length) {
                    i = 0;
                }
                that.eq(i).addClass(name).siblings().removeClass(name);
                content.children().eq(i).fadeIn("slow").siblings().fadeOut("slow");
                content.height(content.children().eq(i).outerHeight(true));
                clearInterval(time);
                run();
            });
        }
        catch (e) {

        }
        $(this).mouseover(function () {
            $(this).addClass(name).siblings().removeClass(name);
            content.children().eq($(this).index()).fadeIn("slow").siblings().fadeOut("slow");
            content.height(content.children().eq($(this).index()).outerHeight(true));
            i = $(this).index();
            clearInterval(time);
            run();
        })
    }
});
// 判断是否跨域登陆
if (getQueryVariable('code')) {
    $.ajaxSettings.async = false;
    murmur = getQueryVariable('murmur')
    $.post(
        post_url + 'account/session/exchange',
        {
            e_id: murmur,
            code: getQueryVariable('code')
        },
        function (data) {
            error_fn(data.error);
            switch (data.error) {
                case 0:
                    setCookie('user', JSON.stringify({ 'e_id': murmur, 't_id': data.t_id, 'token': data.token }), 90);
                    break;
                case 27201:
                    error(i18n[lang].error_27201)
                    break;
                case 27202:
                    error(i18n[lang].error_27202)
                    break;
                case 27203:
                    error(i18n[lang].error_27203)
                    break;
                case 27204:
                    error(i18n[lang].error_27204)
                    break;
                case 27205:
                    error(i18n[lang].error_27205)
                    break;
                case 27206:
                    error(i18n[lang].error_27206)
                    break;
                case 27207:
                    error(i18n[lang].error_27207)
                    break;
            }
        }
    )
    $.ajaxSettings.async = true;
}
// 判断是否为google登陆
if (getCookie('google_key')) {
    $.ajaxSettings.async = false;
    oauthInfo();
    $.ajaxSettings.async = true;
    function oauthInfo () {
        $.post(
            post_url + 'account/google/oauth/info',
            {
                e_id: sessionStorage.getItem('murmur'),
                key: getCookie('google_key')
            },
            function (data) {
                error_fn(data.error);
                setCookie('google_key', "", -1);
                switch (data.error) {
                    case 0:
                        setCookie('user_google', JSON.stringify({ 'google_id_token': data.id_token }), 90);
                        google_login()
                        break;
                    case 26701:
                        error(i18n[lang].error_26701);
                        break;
                    case 26702:
                        error(i18n[lang].error_26702);
                        break;
                    case 26703:
                        error(i18n[lang].error_26703);
                        break;
                    case 26704:
                        error(i18n[lang].error_26704);
                        break;
                    case 26705:
                        oauthInfo();
                        break;
                }
            }
        ).error(function (xhr) {
            overtime(26799);
        });
    }
} else {
    if (!getCookie('user')) {
        // 如果是跨域跳转过来的,5秒后，在跳走
        if (getQueryVariable('code')) {
            setTimeout(() => {
                location.href = "/login";
            }, 5000);
        } else {
            location.href = "/login";
        }
    }
}
//移动端底部
$('.info_bottom .more').click(function () {
    $(this).toggleClass('active');
    $(this).find('ul').toggle();
});
//推荐文章切换
$('.info>.right.faq div.guide .tab_nav span').click(function () {
    $(this).addClass('active').siblings().removeClass('active');
    $(this).parent().next().find('li').eq($(this).index()).addClass('active').siblings().removeClass('active');
});
//查询资料
var personal;
$.post(
    post_url + 'account/profile',
    {
        e_id: JSON.parse(getCookie('user')).e_id,
        t_id: JSON.parse(getCookie('user')).t_id,
        token: JSON.parse(getCookie('user')).token
    },
    function (data) {
        if (!error_fn(data.error)) {
            return false;
        };
        $('.body_bg').hide();
        personal = data;
        switch (data.error) {
            case 0:
                if (JSON.parse(getCookie('user')).token) {
                    $('.login_box').show();
                } else {
                    // $('.login_btn>span').show();
                }
                if (data.picture) {
                    $('.info>.right .user .user_img,.info>.right .edit_account .left div,.header .login_box ul li div,.login_box>div').addClass('active');
                    $('.info>.right .user .user_img img').eq(0).attr('src', data.picture);
                    // $('.mobile-login-list ul li div img').attr('src', data.picture);
                    $('.avatar').attr('src', data.picture);
                }
                if (data.nickname) {
                    $('.info>.right .user p.name span').html(HtmlUtil.htmlEncode(data.nickname));
                    $('.login_box ul li').eq(0).find('.name').html(HtmlUtil.htmlEncode(data.nickname));
                    $('.mobile-login-list ul li').eq(0).find('.name').html(HtmlUtil.htmlEncode(data.nickname));
                } else {
                    if (data.email) {
                        try {
                            var nickname = data.email.split('@')[0];
                        } catch (e) {
                            // console.log(e);
                        }
                        $('.info>.right .user p.name span').html(HtmlUtil.htmlEncode(nickname));
                        $('.login_box ul li').eq(0).find('.name').html(HtmlUtil.htmlEncode(nickname));
                        $('.mobile-login-list ul li').eq(0).find('.name').html(HtmlUtil.htmlEncode(nickname));
                    } else {
                        $('.info>.right .user p.name span').html(data['3rdparty'][0]['name']);
                        $('.login_box ul li').eq(0).find('.name').html(data['3rdparty'][0]['name']);
                        $('.mobile-login-list ul li').eq(0).find('.name').html(data['3rdparty'][0]['name']);
                    }
                }
                if (!data.email_verified) {
                    $('.info>.right .user p.email span').html(HtmlUtil.htmlEncode(data.email) + "&nbsp" + `<span class="verification" style="color:#3774FF;text-decoration: underline;">( ${i18n[lang].verify_email} ) <img src="/images/loading-h.svg" alt="loading"></span>`);
                    $('.login_box ul li .verification').show();
                } else {
                    $('.info>.right .user p.email span').html(HtmlUtil.htmlEncode(data.email));
                    $('.login_box ul li .verification').hide();
                }
                //判断是修改密码还是设置密码 还是三方登录，绑定邮箱
                if (data.session.type == 3 && !data.email) {
                    $('.privacy .bind_email').show();
                } else {
                    if (data.has_password) {
                        $('.privacy .update_password').show();
                    } else {
                        $('.privacy .set_password').show();
                    };
                }
                //显示删除账号
                $(".delet_accout").show();
                break;
            case 20901:
                error(i18n[lang].error_20901);
                break;
            case 20902:
                error(i18n[lang].error_20902);
                break;
        }
    }
).error(function (xhr) {
    $('.body_bg').hide();
    overtime(20999);
});
$(".free_box .tab_nav i").rotation_chart($('.free_box .tab'), 0, 0, "active", 10000);
$(".product_box .tab_nav i").rotation_chart($('.product_box .tab'), 0, 0, "active", 10000);
$('.info>.right.faq .faq ul li>p').click(function (e) {
    e.stopPropagation();
    $(this).parent().toggleClass('active').siblings().removeClass('active');
});
//打开修改头像
var isChangeAvatar = 0;
$('.change_avatar_open').click(function (e) {
    e.stopPropagation();
    if (!isChangeAvatar) {
        $('body').append(`
        <div class="change_avatar" style="display: block;">
            <div class="change_avatar_box">
            <p><span>${i18n[lang].edit_photo}</span> <i class="close"></i></p>
            <div class="file_box"><span>${i18n[lang].select_photo}</span><input type="file" id="FileUpload" accept=".jpg, .jpeg, .png, .bmp, .gif, .webp"></div>
            <div class="img_box" style="display: none;">
                <div class="canvas_box">
                <canvas id="canvas" width="204" height="204"></canvas>
                <canvas id="circular" width="204" height="204"></canvas>
                </div>
                <div class="operation">
                <span><span>${i18n[lang].change_photo}</span><label for="FileUpload"></label></span>
                <img class="enlarge" src="/images/add.svg" alt="Enlarge" title="${i18n[lang].zoom_in}">
                <img class="narrow" src="/images/minimize.svg" alt="narrow" title="${i18n[lang].zoom_out}">
                <img class="left_rotate" src="/images/backup.svg" alt="Backup" title="${i18n[lang].rotate}">
                <!-- <img class="mirror" src="/images/mirror.svg" alt="Backup" title="${i18n[lang].horizontal_flip}">
                <img class="b_mirror" src="/images/v-mirror.svg" alt="Mirror" title="${i18n[lang].vertical_flip}"> --!>
                </div>
            </div>
            <div class="btn">
                <span class="cancel">${i18n[lang].cancel}</span>
                <span class="submit nobind"><span>${i18n[lang].feedback_submit}</span><img src="/images/loading.svg" alt="Loading"></span>
            </div>
            </div>
        </div>
        `);
        isChangeAvatar = 1;
        $('#FileUpload').change(function () {
            $('.change_avatar_box .file_box').hide();
            $('.change_avatar_box .img_box').show();
            $('.change_avatar_box .btn span.submit').removeClass('nobind');
            fileToCanvas(this.files[0]);
            $('#FileUpload').val('');
        });
        //canvas蒙版
        var circular = document.getElementById("circular");
        var circular_2d = circular.getContext("2d");
        // 开始绘制路径
        circular_2d.beginPath();
        circular_2d.lineWidth = 2;
        circular_2d.strokeStyle = '#ccc';
        circular_2d.fillStyle = "rgba(000,000,000,1)";
        circular_2d.fillRect(0, 0, 204, 204);
        circular_2d.arc(102, 102, 100, 0, Math.PI * 2, false);
        circular_2d.globalCompositeOperation = "xor";
        circular_2d.fill();
        circular_2d.globalCompositeOperation = "source-over";
        circular_2d.stroke();

        //canvas操作
        var canvas = document.getElementById("canvas");
        var imageCanvas = canvas.getContext("2d");
        var image;
        var image_w;
        var image_h;
        var X = 102;
        var Y = 102;
        var zoom = 1;
        var direction = 1;
        $('body').on('click', '.change_avatar_box .btn span.cancel', function () {
            $('.change_avatar_box .file_box').show();
            $('.change_avatar_box .img_box').hide();
            $('.change_avatar_box .btn span.submit').addClass('nobind');
            $('.change_avatar').hide();
        });
        $('body').on('click', '.change_avatar_box .close', function () {
            $('.change_avatar_box .file_box').show();
            $('.change_avatar_box .img_box').hide();
            $('.change_avatar_box .btn span.submit').addClass('nobind');
            $('.change_avatar').hide();
        });
        $('body').on('click', '.change_avatar_box .btn span.submit', function () {
            if (!isnetwork()) { return false; };
            if ($(this).attr('class').indexOf('nobind') >= 0) {
                return false;
            } else {
                let that = $(this);
                that.find('img').css('opacity', 1);
                $.post(
                    post_url + 'account/profile/update',
                    {
                        e_id: murmur,
                        t_id: JSON.parse(getCookie('user')).t_id,
                        token: JSON.parse(getCookie('user')).token,
                        picture: canvas.toDataURL("image/png"),
                    },
                    function (data) {
                        that.find('img').css('opacity', 0);
                        error_fn(data.error)
                        switch (data.error) {
                            case 0:
                                $('.info>.right .user .user_img,.info>.right .edit_account .left div,.header .login_box ul li div,.login_box>div').addClass('active');
                                $('.info>.right .user .user_img img').eq(0).attr('src', canvas.toDataURL("image/png"));
                                $('.info>.right .edit_account .left img').eq(0).attr('src', canvas.toDataURL("image/png"));
                                // $('.login_box ul li div img').attr('src', canvas.toDataURL("image/png"));
                                $('.avatar').attr('src', canvas.toDataURL("image/png"));
                                $('.change_avatar').hide();
                                success(i18n[lang].error_21000);
                                $('.change_avatar_box .file_box').show();
                                $('.change_avatar_box .img_box').hide();
                                $('.change_avatar_box .btn span.submit').addClass('nobind');
                                break;
                            case 21001:
                                error(i18n[lang].error_21001);
                                break;
                            case 21002:
                                error(i18n[lang].error_21002);
                                break;
                        }
                    }
                ).error(function (xhr) {
                    overtime(21099);
                });
            }
        });
        function fileToCanvas (file) {
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function (event) {
                image = new Image();
                image.src = event.target.result;
                image.onload = function () {
                    imageCanvas.clearRect(0, 0, 204, 204);
                    for (var i = 0; i < direction; i++) {
                        imageCanvas.clearRect(0, 0, 204, 204);
                        $('.left_rotate').click();
                    }
                    zoom = 1;
                    direction = 1
                    if (image.width < image.height) {
                        image_w = 204;
                        image_h = image.height / (image.width / 204);
                    } else {
                        image_h = 204;
                        image_w = image.width / (image.height / 204);
                    }
                    X = 102;
                    Y = 102;
                    X = X - image_w / 2;
                    Y = Y - image_h / 2;
                    imageCanvas.drawImage(image, X, Y, image_w, image_h);
                }
            }
        }
        function canvasToImage (canvas) {
            var image = new Image();
            image.src = canvas.toDataURL("image/png");//base64
        }
        //放大缩小
        $('body').on('click', '.narrow', function () {
            zoom -= 0.2;
            if (zoom < 0.2) {
                zoom = 0.2;
            }
            imageCanvas.clearRect(0, 0, 204, 204);
            imageCanvas.drawImage(image, X, Y, image_w * zoom, image_h * zoom);
        });
        $('body').on('click', '.enlarge', function () {
            zoom += 0.2;
            imageCanvas.clearRect(0, 0, 204, 204);
            imageCanvas.drawImage(image, X, Y, image_w * zoom, image_h * zoom);
        });
        //旋转
        $('body').on('click', '.left_rotate', function () {
            imageCanvas.clearRect(0, 0, canvas.width, canvas.height);
            imageCanvas.translate(102, 102);
            imageCanvas.rotate((Math.PI / 180) * -90);
            imageCanvas.translate(-102, -102);
            imageCanvas.drawImage(image, X, Y, image_w * zoom, image_h * zoom);
            direction++;
            if (direction > 4) {
                direction = 1
            }
        });
        //镜像
        $('body').on('click', '.mirror', function () {
            imageCanvas.clearRect(0, 0, canvas.width, canvas.height);
            imageCanvas.translate(102, 102);
            imageCanvas.scale(-1, 1); //左右镜像翻转
            imageCanvas.translate(-102, -102);
            imageCanvas.drawImage(image, X, Y, image_w * zoom, image_h * zoom);
        });
        //垂直镜像
        $('body').on('click', '.b_mirror', function () {
            imageCanvas.clearRect(0, 0, canvas.width, canvas.height);
            imageCanvas.translate(102, 102);
            imageCanvas.scale(1, -1); //垂直翻转
            imageCanvas.translate(-102, -102);
            imageCanvas.drawImage(image, X, Y, image_w * zoom, image_h * zoom);
        });
        // 移动
        var mousemoveX = 0;
        var mousemoveY = 0;
        $('body').on('mousedown', '.canvas_box', function (e) {
            var mousedownX = e.offsetX;
            var mousedownY = e.offsetY;
            $('body').on('mousemove', '.canvas_box', function (e) {
                imageCanvas.clearRect(0, 0, canvas.width, canvas.height);
                switch (direction) {
                    case 1:
                        mousemoveX = e.offsetX - mousedownX + X;
                        mousemoveY = e.offsetY - mousedownY + Y;
                        imageCanvas.drawImage(image, mousemoveX, mousemoveY, image_w * zoom, image_h * zoom);
                        break;
                    case 2:
                        mousemoveX = e.offsetX - mousedownX + X;
                        mousemoveY = mousedownY - e.offsetY + Y;
                        imageCanvas.drawImage(image, mousemoveY, mousemoveX, image_w * zoom, image_h * zoom);
                        break;
                    case 3:
                        mousemoveX = mousedownX - e.offsetX + X;
                        mousemoveY = mousedownY - e.offsetY + Y;
                        imageCanvas.drawImage(image, mousemoveX, mousemoveY, image_w * zoom, image_h * zoom);
                        break;
                    case 4:
                        mousemoveX = mousedownX - e.offsetX + X;
                        mousemoveY = e.offsetY - mousedownY + Y;
                        imageCanvas.drawImage(image, mousemoveY, mousemoveX, image_w * zoom, image_h * zoom);
                        break;
                };
            });
            $('body').mouseup(function () {
                // switch(direction){
                //     case 1:
                //         X=mousemoveX
                //         Y=mousemoveY
                //         break;
                //     case 2:
                //         X=mousemoveY
                //         Y=mousemoveX
                //         break;
                //     case 3:
                //         X=mousemoveX
                //         Y=mousemoveY
                //         break;
                //     case 4:
                //         X=mousemoveY
                //         Y=mousemoveX
                //         break;
                // };
                X = mousemoveX
                Y = mousemoveY
                $('body').unbind('mousemove');
            });
        });
    };
    $('.change_avatar').show();
});
if (location.href.split('type=')[1] == 1) {
    setTimeout(function () {
        $(window).scrollTop($('.info').offset().top - 130)
    }, 100);
}
// faq页面链接多语言处理
if (lang == 'ja') {
    $('.get_more').attr('href', 'https://www.eassiy.com/ja/resource/');
    $('.contact_us').attr('href', 'https://www.eassiy.com/ja/contact/');
    $('.banner').attr('href', 'https://www.eassiy.com/ja/special/promotion/');
    $('.right .guide .en').hide();
    $('.right .guide .de').hide();
    $('.right .guide .fr').hide();
    $('.right .guide .jp').show();
} else if (lang == 'de') {
    $('.get_more').attr('href', 'https://www.eassiy.com/de/resource/');
    $('.contact_us').attr('href', 'https://www.eassiy.com/de/contact.html');
    $('.banner').attr('href', 'https://www.eassiy.com/de/special/promotion.html');
    $('.right .guide .en').hide();
    $('.right .guide .fr').hide();
    $('.right .guide .jp').hide();
    $('.right .guide .de').show();
} else if (lang == 'fr') {
    $('.get_more').attr('href', 'https://www.eassiy.com/fr/resource/');
    $('.contact_us').attr('href', 'https://www.eassiy.com/fr/contact.html');
    $('.banner').attr('href', 'https://www.eassiy.com/fr/special/promotion/');
    $('.right .guide .en').hide();
    $('.right .guide .jp').hide();
    $('.right .guide .de').hide();
    $('.right .guide .fr').show();
} else {
    $('.right .guide .en').show();
    $('.right .guide .jp').hide();
    $('.right .guide .de').hide();
    $('.right .guide .fr').hide();
};
// 邮箱验证点击
$('body').on('click', '.verification', function () {
    var that = $(this);
    that.addClass('unbind');
    $.post(
        post_url + "account/authcode/email/verify",
        {
            e_id: murmur,
            t_id: JSON.parse(getCookie('user')).t_id,
            token: JSON.parse(getCookie('user')).token,
            language: lang.toLowerCase()
        }, function (data) {
            that.removeClass('unbind');
            error_fn(data.error);
            switch (data.error) {
                case 0:
                    location.href = "/email-verification?email=" + personal.email;
                    break;
                case 26401:
                    error(i18n[lang].error_26401);
                    break;
                case 26402:
                    $('.info>.right .edit_account .edit_email_box div div .verification').hide()
                    $('.info>.right .edit_account .edit_email_box>p').hide();
                    $('.info>.right .user p.email .verification').hide();
                    $('.header .login_box ul li:first-child>p .verification').hide();
                    break;
                case 26403:
                    error(i18n[lang].error_26403);
                    break;
                case 26404:
                    error(i18n[lang].error_26404);
                    break;
            }
        }
    ).error(function (xhr) {
        overtime(26499);
    });
});